import "./style.scss";

const Map = () => {
  return (
    <div id="Map">
      <div className="container-md container-fluid d-flex justify-content-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.162633289081!2d49.871538!3d40.383088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d1be2bff7d5%3A0x30c4ea6a9cadf772!2zRMmZbWlyw6dpIFBsYXph!5e0!3m2!1str!2s!4v1674070260974!5m2!1str!2s"
          height="276"
          title="Expargo"
          style={{ border: 0, width: "100%", borderRadius: 20, maxWidth: 1200 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
