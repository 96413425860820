import React from "react";
import "./style.scss";
const Input = ({ name, label, data, icon, ...rest }) => {
  return (
    <div id="Input">
      <label htmlFor={name}>{label}</label>
      <div className="input-box">
        {icon ? <img src={icon} alt="" /> : ""}
        <input name={name} type="number" {...rest} />
      </div>
    </div>
  );
};

export default Input;
