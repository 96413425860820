import React from "react";
import "./style.scss";
import MapImage from "../../assets/icon/map.png";
import { Button, Calculator } from "../../components";
import useTranslate from "../../hooks/useTranslate";

const Hero = () => {
  const translate = useTranslate();
  return (
    <div id="Hero">
      <div className="container-md container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h1>{translate("Hero_title")}</h1>
            <p>{translate("Hero_description")}</p>
            <Button>{translate("Hero_button")}</Button>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <Calculator />
          </div>
        </div>
      </div>
      <img src={MapImage} alt="" />
    </div>
  );
};

export default Hero;
