import "./style.scss";
import { Button } from "../../components";
import email from "../../assets/icon/email-icon.svg";
import telephone from "../../assets/icon/telephone-icon.svg";
import pin from "../../assets/icon/pin-icon.svg";
import useTranslate from "../../hooks/useTranslate";
import { useState } from "react";

const initialState = {
  email_address: "",
  content: "",
  subject: "",
};

const Contact = () => {
  const translate = useTranslate();
  const [validation, setValidation] = useState(false);
  const [submitButton, setSubmitButton] = useState(
    translate("Contact__button")
  );
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    if (!formData.content.trim() || !formData.email_address.trim())
      return setValidation(true);
    else setValidation(false);
    setSubmitButton("Göndərilir...");
    try {
      await fetch("https://api.expargo.com/email/contact", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());
    } catch (err) {
    } finally {
      setSubmitButton("Göndərildi!");
      setFormData(initialState);
    }
    setTimeout(() => {
      setSubmitButton("Göndər");
    }, 3000);
  };

  return (
    <div id="Contact">
      <div className="container-md container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className="title text-center mb-5">
              {translate("Contact_title")}
            </h1>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-6">
            <label htmlFor="email">
              {translate("Contact__email_label")}
              <input
                type="email"
                placeholder={translate("Contact__email_placeholder")}
                name="email_address"
                id="email"
                value={formData.email_address}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="message">
              {translate("Contact__message_label")}
              <textarea
                type="email"
                placeholder={translate("Contact__message_placeholder")}
                name="content"
                id="message"
                value={formData.content}
                onChange={handleChange}
              />
            </label>
            {validation ? (
              <p style={{ color: "red" }}>
                * {translate("Contact__validation")}
              </p>
            ) : (
              ""
            )}
            <Button
              className="w-100 text-center"
              onClick={handleSubmit}
              notLink
            >
              {submitButton}
            </Button>
          </div>

          <div className="col-12 col-lg-6 d-block d-lg-flex mt-5 mt-lg-0 justify-content-center">
            <div className="contact-info">
              <div>
                <img src={email} alt="" />
                <h2>
                  <a href="mailto:commercial@expargo.com">commercial@expargo.com</a>
                </h2>
              </div>
              <div>
                <img src={telephone} alt="" />
                <div>
                  <h2>
                    <a href="tel:+994 (12) 310 34 49">+994 (12) 310 34 49</a>
                  </h2>
                  <p>
                    {translate("Contact__work_hours")} <br />
                    {translate("Contact__work_hours__weekdays")} <br />
                    {translate("Contact__work_hours__saturday")}
                  </p>
                </div>
              </div>
              <div>
                <img src={pin} alt="" />
                <h2>
                  Damirchi Plaza <br />
                  Khojaly Pr 37 <br />
                  Baku, Azerbaijan
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
