import Select from "../Select";
import "./style.scss";
import PinIcon from "../../assets/icon/pin-calculator.svg";
import WeightIcon from "../../assets/icon/weight-calculator.svg";
import DimensionIcon from "../../assets/icon/dimension-calculator.svg";
import Button from "../Button";
import Input from "../Input";
import { useEffect, useState } from "react";
import useTranslate from "../../hooks/useTranslate";

const Calculator = () => {
  const [origin, setOrigin] = useState("TR");
  const [weight, setWeight] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [calcData, setCalcData] = useState(null);
  const [cost, setCost] = useState(0);

  const translate = useTranslate();
  const originData = [
    {
      id: 1,
      label: translate("Turkey"),
      value: "TR",
    },
    {
      id: 2,
      label: translate("Europe"),
      value: "EU",
    },
    {
      id: 3,
      label: translate("China"),
      value: "CH",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let data = await fetch(
        "https://api.expargo.com/store/GROUPAGE_CALC"
      ).then((res) => res.json());
      data = JSON.parse(data);
      setCalcData(JSON.parse(data.value));
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let setData;
    let cD = 0;
    let cW = 0;
    switch (name) {
      case "origin":
        cD = calcData[value].M3 * Number(dimensions);
        cW = calcData[value].KG * Number(weight);
        setData = setOrigin;
        break;
      case "weight":
        cD = calcData[origin].M3 * Number(dimensions);
        cW = calcData[origin].KG * Number(value);
        setData = setWeight;
        break;
      default:
        cD = calcData[origin].M3 * Number(value);
        cW = calcData[origin].KG * Number(weight);
        setData = setDimensions;
        break;
    }

    setData(value);

    setCost(cW < cD ? cD : cW);
  };
  return (
    <div id="Calculator">
      <h6 className="text-center">{translate("Calculator_title")}</h6>

      <Select
        label={translate("Calculator_from")}
        icon={PinIcon}
        data={originData}
        name="origin"
        onChange={handleChange}
        value={origin}
      />

      <Input
        label={translate("Calculator_weight")}
        placeholder={translate("Calculator_weight")}
        icon={WeightIcon}
        name="weight"
        onChange={handleChange}
        value={weight}
      />
      <Input
        label={translate("Calculator_dimensions")}
        placeholder={translate("Calculator_dimensions")}
        icon={DimensionIcon}
        name="dimensions"
        onChange={handleChange}
        value={dimensions}
      />

      <Button notLink disabled>
        ${cost.toFixed(2) > 0 ? (cost + 100).toFixed(2) : cost.toFixed(2)}
      </Button>
    </div>
  );
};

export default Calculator;
