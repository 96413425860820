import Image from "../../assets/images/image-1.png";
import { Button } from "../../components";
import useTranslate from "../../hooks/useTranslate";
import "./style.scss";

const Warehouses = () => {
  const translate = useTranslate();
  return (
    <div id="Warehouses">
      <div className="container-md contaienr-fluid">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={Image} alt="Warehouses images" />
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <div className="content px-0 px-lg-5">
              <h1 className="title">{translate("Warehouses_title")}</h1>
              <p className="description">
                {translate("Warehouses_description")}
              </p>
              <Button className="mt-3">{translate("Services_button")}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warehouses;
