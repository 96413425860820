import { createContext, useState } from "react";
import { Footer, Header } from "./components";
import {
  Hero,
  Warehouses,
  Advantages,
  Contact,
  Map,
  Collaborator,
  GuaranteedAndSafe,
} from "./layouts";
export const AppContext = createContext();
function App() {
  const [lang, setLang] = useState("AZ");

  return (
    <AppContext.Provider value={{ lang, setLang }}>
      <div className="App">
        <Header />

        <Hero />
        <div id="Services">
          <Warehouses />
          <Collaborator />
          <GuaranteedAndSafe />
        </div>
        <Advantages />
        <Contact />
        <Map />
        <Footer />
      </div>
    </AppContext.Provider>
  );
}

export default App;
