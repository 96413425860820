import { useContext, useEffect, useState } from "react";
import Logo from "../../assets/icon/logo.svg";
import useTranslate from "../../hooks/useTranslate";
import "./style.scss";

import Menu from "../../assets/icon/menu.svg";
import Close from "../../assets/icon/close.svg";

import azflag from "../../assets/flag/az.png";
import enflag from "../../assets/flag/en.webp";
import ruflag from "../../assets/flag/ru.png";
import { AppContext } from "../../App";

const Header = () => {
  const { setLang, lang } = useContext(AppContext);
  const [langDropdown, setLangDropdown] = useState(false);
  const translate = useTranslate();
  const [bg, setBg] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const menu = [
    {
      name: translate("Header_main"),
      url: "#Hero",
    },
    {
      name: translate("Header_services"),
      url: "#Services",
    },
    {
      name: translate("Header_contact"),
      url: "#Contact",
    },
    {
      name: translate("E-commerce"),
      url: "https://expargo.com",
      target: "_blank",
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 80) {
        setBg(true);
      }
      if (window.scrollY === 0) {
        setBg(false);
      }
    });
  }, []);
  const changeLang = (l) => {
    setLang(l);
    window.location.pathname = `/${l.toLowerCase()}`;
  };
  return (
    <div id="Header" className={bg ? "fixed" : ""}>
      <div className="container-md container-fluid">
        <div className="row">
          <div className="col-12">
            <header>
              <div className="logo">
                <img src={Logo} alt="Logo" height={45} />
              </div>
              <nav className={mobileMenu ? "active" : ""}>
                <div className="logo d-block d-md-none">
                  <img
                    src={Logo}
                    alt="Logo"
                    height={45}
                    style={{ marginLeft: "-5px" }}
                  />
                </div>

                <div className="close"></div>
                <ul>
                  {menu.map(({ name, url, target }, i) => (
                    <li key={i}>
                      <a href={url} target={target || "_self"}>
                        {name}
                      </a>
                    </li>
                  ))}
                  <li onClick={() => setLangDropdown(!langDropdown)}>
                    <button className="lang-button">
                      <img
                        src={
                          lang === "AZ"
                            ? azflag
                            : lang === "EN"
                            ? enflag
                            : ruflag
                        }
                        alt=""
                      />
                      <i className="fa-solid fa-chevron-down"></i>
                      <div
                        className={`lang-dropdown ${
                          langDropdown ? "active" : ""
                        }`}
                      >
                        <ul>
                          <li onClick={() => changeLang("AZ")}>
                            <span>AZ</span>
                            <img src={azflag} alt="" />
                          </li>
                          <li onClick={() => changeLang("EN")}>
                            <span>EN</span>
                            <img src={enflag} alt="" />
                          </li>
                          <li onClick={() => changeLang("RU")}>
                            <span>RU</span>
                            <img src={ruflag} alt="" />
                          </li>
                        </ul>
                      </div>
                    </button>
                  </li>
                </ul>
                <button
                  id="close"
                  className="d-block d-md-none"
                  onClick={() => setMobileMenu(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </nav>

              <button
                id="toggle"
                className="d-block d-md-none"
                onClick={() => setMobileMenu(!mobileMenu)}
              >
                <img src={Menu} alt="" />
              </button>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
