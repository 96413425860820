import useTranslate from "../../hooks/useTranslate";
import card1 from "../../assets/icon/advantages-icon-1.svg";
import card2 from "../../assets/icon/advantages-icon-2.svg";
import card3 from "../../assets/icon/advantages-icon-3.svg";
import './style.scss'

const Advantages = () => {
  const translate = useTranslate();
  return (
    <div id="Advantages">
      <div className="container-md container-fluid">
        <div className="row text-center">
          <div className="col-12">
            <h1 className="title">{translate("Advantages_title")}</h1>
          </div>
          <div className="col-12">
            <div className="card-container">
              <div className="_card">
                <img src={card1} alt="" />
                <h2 className="_card__title">
                  {translate("Advantages__card_one_title")}
                </h2>
                <p className="_card__description">
                  {translate("Advantages__card_one_description")}
                </p>
              </div>
              <div className="_card">
                <img src={card2} alt="" />
                <h2 className="_card__title">
                  {translate("Advantages__card_two_title")}
                </h2>
                <p className="_card__description">
                  {translate("Advantages__card_two_description")}
                </p>
              </div>
              <div className="_card">
                <img src={card3} alt="" />
                <h2 className="_card__title">
                  {translate("Advantages__card_three_title")}
                </h2>
                <p className="_card__description">
                  {translate("Advantages__card_three_description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
