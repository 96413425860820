import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import langData from "../data/lang.json";
import Settings from "../utils/setting";

const useTranslate = () => {
  const { lang, setLang } = useContext(AppContext);


  useEffect(() => {
    if (window.location.pathname.substring(1).toUpperCase() === lang) return;
    switch (window.location.pathname.substring(1)) {
      case "az":
        setLang("AZ");
        break;
      case "en":
        setLang("EN");
        break;
      case "ru":
        setLang("RU");
        break;
      default:
        setLang("AZ");
        break;
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  const settings = new Settings();
  settings.lang = lang;

  let translate;
  translate = (key) => langData[key][settings.lang];

  return translate;
};

export default useTranslate;
