import "./style.scss";

const Button = ({ children, notLink, ...rest }) => {
  return notLink ? (
    <button id="button" {...rest}>
      {children}
    </button>
  ) : (
    <a href="#Contact">
      <button id="button" {...rest}>
        {children}
      </button>
    </a>
  );
};

export default Button;
