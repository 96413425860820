import React from "react";
import "./style.scss";
const Select = ({ name, label, data, icon, value, ...rest }) => {
  return (
    <div id="Select">
      <label htmlFor={name}>{label}</label>
      <div className="select-box">
        {icon ? <img src={icon} alt="" /> : ""}
        <select name={name} id={name} defaultValue={value} {...rest}>
          {data?.map(({ label, value,  id }) => (
            <option value={value} key={id}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
