class Settings {
  #lang = "AZ";

  get lang() {
    return this.#lang;
  }

  set lang(l) {
    this.#lang = l;
  }
}

export default Settings;
