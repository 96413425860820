import React from "react";
import Logo from "../../assets/icon/logo.png";
import useTranslate from "../../hooks/useTranslate";
import "./style.scss";
const Footer = () => {
  const translate = useTranslate();
  const menu = [
    {
      name: translate("Header_main"),
      url: "#Hero",
    },
    {
      name: translate("Header_services"),
      url: "#Services",
    },
    {
      name: translate("Header_contact"),
      url: "#Contact",
    },
    {
      name: translate("E-commerce"),
      url: "https://expargo.com",
      target: "_blank",
    },
  ];
  return (
    <div id="Footer">
      <div className="container-md container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={Logo} alt="" height={29} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 col-12">
            <p className="mt-4">
              Damirchi Plaza <br />
              Khojaly Pr 37 <br />
              Baku, Azerbaijan
            </p>

            <p className="mt-4">
              <a href="tel:+994 (12) 310 34 49">+994 (12) 310 34 49</a>
            </p>

            <p className="mt-4">
              {translate("Contact__work_hours__weekdays")} <br />
              {translate("Contact__work_hours__saturday")}
            </p>
          </div>

          <div className="col-md-4 col-12">
            <ul>
              {menu.map(({ name, url, target }, i) => (
                <li key={i}>
                  <a href={url} target={target || "_self"}>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4 col-12">
            <h5 className="mb-3">{translate("Follow_us")}</h5>
            <div className="social">
              <a href="#/">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a href="#/">
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href="#/">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </div>
            <br />
            <p>© Copyright 2018-2022, All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
